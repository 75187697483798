import { Link } from "@nextui-org/link"
import { Tooltip } from "@nextui-org/tooltip"
import { FC, useCallback } from "react"
import { IconType } from "react-icons"
import * as RiIcons from "react-icons/ri"
import { RiArrowRightUpLine } from "react-icons/ri"

import { formatHackathonDate } from "@/lib/formatHackathonDate"

type IconInfo = {
  name: string
  isRiIcon: boolean
}

type IconTextItemProps = {
  iconInfo: IconInfo
  text: string | string[] | number | number[]
  description?: string
  iconClassName?: string
  isDate?: boolean
  isLink?: boolean
  link?: string
  linkDescription?: string
}

export const IconTextItem: FC<IconTextItemProps> = ({
  iconInfo,
  text,
  description,
  iconClassName = "w-6 h-6 text-gray-400",
  isDate = false,
  isLink = false,
  link = "#",
  linkDescription = "#",
}) => {
  const IconComponent =
    iconInfo.isRiIcon && iconInfo.name in RiIcons
      ? (RiIcons[iconInfo.name as keyof typeof RiIcons] as IconType)
      : () => <span>{iconInfo.name}</span>

  const renderText = useCallback(
    (item: string | number) => {
      return isDate ? (
        <span suppressHydrationWarning>
          {formatHackathonDate(Number(item))}
        </span>
      ) : (
        item
      )
    },
    [isDate],
  )

  const content = (
    <div className="flex w-full items-center space-x-2">
      <div className="flex-shrink-0">
        <IconComponent className={iconClassName} aria-hidden="true" />
      </div>
      <div className="min-w-0 flex-grow">
        {Array.isArray(text) ? (
          text.map((item) => (
            <p key={item} className="truncate text-base font-bold">
              {renderText(item)}
            </p>
          ))
        ) : (
          <p className="truncate text-sm font-bold">{renderText(text)}</p>
        )}
        {description != null && (
          <p className="truncate text-[10px] leading-4">{description}</p>
        )}
      </div>
      {link && isLink && (
        <div className="ml-2 flex-shrink-0">
          <RiArrowRightUpLine
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
      )}
    </div>
  )

  if (link !== "" && isLink) {
    return (
      <Tooltip
        content={
          <Link href={link} isExternal color="secondary">
            <p className="text-sm">{linkDescription}</p>
          </Link>
        }
        isDisabled={!isLink}
      >
        <Link
          href={link}
          className="flex w-full items-center justify-between truncate rounded p-2"
          isExternal
          color="secondary"
        >
          {content}
        </Link>
      </Tooltip>
    )
  }

  return (
    <div className="flex w-full items-center justify-between">{content}</div>
  )
}
