const _format2Digits = (num: number): string => {
  return `${(num >= 10 ? "" : "0") + num.toString()}`
}

const _getDate = (date: Date): string => {
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${_format2Digits(month)}/${_format2Digits(day)}`
}

const _getTime = (date: Date): string => {
  const hour = date.getHours()
  const minutes = date.getMinutes()

  return `${_format2Digits(hour)}:${_format2Digits(minutes)}`
}

const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]

export const formatHackathonDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000) // Unixタイムスタンプをミリ秒に変換

  const formatDate = `${_getDate(date)}(${dayOfWeek[date.getDay()]})${_getTime(date)}`
  return formatDate
}

export const formatHackathonCardDate = (timestamp: number): string => {
  const date = new Date(timestamp * 1000)
  return _getDate(date)
}
