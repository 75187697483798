import { Link } from "@nextui-org/link"
import { FC, ReactNode } from "react"
import { RiGlobalLine, RiInstagramFill, RiYoutubeFill } from "react-icons/ri"

import { TSponsorUrls } from "@/api/raid"
import { TwitterIcon } from "@/components/icons"

type Props = {
  urls: TSponsorUrls
}

const iconMap = new Map<
  "web" | "youtube" | "instagram" | "x" | "image",
  ReactNode
>([
  ["x", <TwitterIcon key="x" className="text-gray-400" size={20} />],
  [
    "youtube",
    <RiYoutubeFill key="youtube" className="text-gray-400" size={24} />,
  ],
  [
    "instagram",
    <RiInstagramFill key="instagram" className="text-gray-400" size={24} />,
  ],
  ["web", <RiGlobalLine key="web" className="text-gray-400" size={24} />],
])

export const SocialIcons: FC<Props> = ({ urls }) => {
  return (
    <div className="flex flex-shrink-0 items-center space-x-2 sm:space-x-3 md:space-x-4">
      {urls
        .filter((url) => url.type !== "image")
        .map((url) => (
          <Link href={url.url} isExternal key={url.type + url.url}>
            {iconMap.get(url.type) ?? ""}
          </Link>
        ))}
    </div>
  )
}
